.StripeElement {
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 13px 15px;
  margin-bottom: 20px;
  outline: none;
}

.Stripe-card-expiry-element {
  width: calc(100% - 100px);
}

.Stripe-card-cvc-element {
  width: 80px;
}
